<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='createUser'"
      tabindex="-1"
      role="dialog"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add User</h5>
            <a class="close" title="Close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="addUser"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mt-0 mb-1">
                            <label for="userImage" style="border: 1px dashed;">
                              <img
                                v-if="formData.image"
                                :src="formData.image"
                                class="uploading-image"
                                style="width: 5rem;height: 4rem;"
                              />
                              <span v-else style="padding: 1rem;">Image</span>
                            </label>
                            <input
                              type="file"
                              id="userImage"
                              accept="image/jpeg"
                              @change="base64Image"
                              style="display:none;"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Name *</div>
                          <div
                            class="col-md-7 input-group ml-0 pl-0"
                            :class="{ invalid: $v.formData.name.$error }"
                          >
                            <div class="wd-100p">
                              <input
                                type="text"
                                class="form-control"
                                id="name"
                                placeholder="Name"
                                @blur="$v.formData.name.$touch()"
                                v-model.trim="formData.name"
                                required
                              />
                            </div>
                            <span class="tx-danger mg-t-2" v-if="$v.formData.name.$error">
                              <p
                                class="error_level mb-0"
                                v-if="!$v.formData.name.required"
                              >Name field is required.</p>
                              <p
                                class="error_level mb-0"
                                v-if="!$v.formData.name.minLength"
                              >At least 2 character name.</p>
                            </span>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Address *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Address"
                              @blur="$v.formData.address.$touch()"
                              v-model.trim="formData.address"
                              required
                            />
                            <span class="tx-danger mg-t-2" v-if="$v.formData.address.$error">
                              <span
                                class="error_level"
                                v-if="!$v.formData.address.minLength"
                              >At least 2 character address.</span>
                              <span
                                class="error_level"
                                v-if="!$v.formData.address.maxLength"
                              >Max 100 character address.</span>
                            </span>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Mobile No *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="tel"
                              class="form-control"
                              id="mobileNo"
                              placeholder="Contact No."
                              @blur="$v.formData.phone.$touch()"
                              v-model.trim="formData.phone"
                              required
                            />
                            <span class="tx-danger mg-t-2" v-if="$v.formData.phone.$error">
                              <p
                                class="error_level mb-0"
                                v-if="!$v.formData.phone.minLength"
                              >At least 7 character phone.</p>
                              <p
                                class="error_level mb-0"
                                v-if="!$v.formData.phone.numeric"
                              >Please enter numeric value..</p>
                              <p
                                class="error_level mb-0"
                                v-if="!$v.formData.phone.maxlength"
                              >Max 25 character phone.</p>
                            </span>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Status *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select class="form-control" v-model="formData.status" id="user_status">
                              <option value="1">Active</option>
                              <option value="0">In-Active</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Email *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="email"
                              class="form-control border-radius-0"
                              placeholder="Email"
                              @blur="$v.formData.email.$touch()"
                              v-model.trim="formData.email"
                              required
                            />
                            <div class="col-md-12 text-right" v-if="$v.formData.email.$error">
                              <p
                                class="error_level"
                                v-if="!$v.formData.email.required"
                              >Email field is required.</p>
                              <p
                                class="error_level"
                                v-if="!$v.formData.email.minLength"
                              >At least 6 character email.</p>
                              <p
                                class="error_level"
                                v-if="!$v.formData.email.maxLength"
                              >Max 50 character email.</p>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Password *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="password"
                              class="form-control border-radius-0"
                              placeholder="Password"
                              @blur="$v.formData.password.$touch()"
                              v-model.trim="formData.password"
                              required
                            />
                            <span class="col-md-12 text-right" v-if="$v.formData.password.$error">
                              <span
                                class="error_level"
                                v-if="!$v.formData.password.required"
                              >Password is required.</span>
                              <span
                                class="error_level"
                                v-if="!$v.formData.password.minLength"
                              >At least 6 character password.</span>
                              <span
                                class="error_level"
                                v-if="!$v.formData.password.minLength"
                              >Max 50 character password.</span>
                            </span>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Confirm Password *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="password"
                              class="form-control border-radius-0"
                              placeholder="Password Confirmation"
                              @blur="$v.formData.password_confirmation.$touch()"
                              v-model.trim="formData.password_confirmation"
                              required
                            />
                            <span
                              class="col-md-12 text-right"
                              v-if="$v.formData.password_confirmation.$error"
                            >
                              <span
                                class="error_level"
                                v-if="!$v.formData.password_confirmation.required"
                              >Password confirmation is required.</span>
                              <span
                                class="error_level"
                                v-if="!$v.formData.password_confirmation.minLength"
                              >At least 6 character password confirmation.</span>
                              <span
                                class="error_level"
                                v-if="!$v.formData.password_confirmation.minLength"
                              >Max 50 character password confirmation.</span>
                            </span>
                            <span
                              class="col-md-12 text-right error_level"
                              v-if="password_error"
                            >{{password_error}}</span>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Role *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control border-radius-0"
                              v-model="formData.role_id"
                              required
                            >
                              <option value>Select Role</option>
                              <option
                                v-for="(role,key) in userElements.roles"
                                :key="key"
                                :value="role.id"
                              >{{role.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Branch *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <v-select
                              name="branch_id"
                              placeholder="Select Branch"
                              class="form-control ht-auto-i"
                              label="name"
                              :options="options"
                              :key="options.id"
                              multiple
                              v-model="formData.branch_id"
                            ></v-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :disabled="disableSubmitButton"
                >{{(disableSubmitButton)?'Submitting...':'Submit'}}</button>
                <p class="tx-danger"
                >{{error_msg}}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  maxLength,
  minLength,
  numeric,
  required,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import axios from "axios";
import Services from "./Services/Services";

export default {
  computed: {
    ...mapGetters([
      "apiRoot",
      "token",
      "processing",
      "fileLocation",
      "eventMessage",
      "dataLists1",
      "modalId",
      "modalState",
    ]),
    ...mapGetters("users", ["userLists", "userElements"]),
  },
  data() {
    return {
      password_error: null,
      formData: {
        name: "",
        email: "",
        phone: "",
        address: "",
        password: "",
        password_confirmation: "",
        image: "",
        status: 1,
        referral_code: "",
        branch_id: "",
        role_id: "",
      },
      options: [],
      roles: [],
      disableSubmitButton: false,
      error_msg:"",
    };
  },
  validations: {
    formData: {
      name: { required, minLength: minLength(2), maxLength: maxLength(255) },
      email: { required, minLength: minLength(6), maxLength: maxLength(50) },
      address: { minLength: minLength(2), maxLength: maxLength(100) },
      password: { required, minLength: minLength(6), maxLength: maxLength(50) },
      password_confirmation: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      branch_id: {
        required,
      },
      phone: { numeric, minLength: minLength(7), maxLength: maxLength(25) },
    },
  },
  methods: {
    base64Image(e) {
      this.formData.image = null;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formData.image = e.target.result;
      };
    },
    addUser() {
      if (!this.$v.formData.$invalid) {
        this.disableSubmitButton = true;
        Services.createNewUser(this.formData)
          .then((res) => {
            this.setNotification(res.data.success_message);
            this.$emit("parent-event");
            this.$store.dispatch("modalClose");
            this.disableSubmitButton = false;
          })
          .catch((err) => {
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              let error = err.response.data.error_message;
              this.setNotification(error);
            }
          })
          .finally(() => {
            this.disableSubmitButton = false;
          });
      } else {
        this.error_msg = 'Please fill required fields.';
      }
    },
    getElements() {
      Services.getElements()
        .then((res) => {
          this.$store.commit("users/setUserElements", res.data.data);
          this.options = res.data.data.branchs;
          console.log(this.options)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    clearData() {
      this.password_error = null;
      this.formData.name = "";
      this.formData.email = "";
      this.formData.phone = "";
      this.formData.address = "";
      this.formData.password = "";
      this.formData.password_confirmation = "";
      this.formData.image = "";
      this.formData.status = 1;
      this.formData.referral_code = "";
      this.formData.branch_id = "";
      this.formData.role_id = "";
      this.options = [];
      this.$v.$reset();
    },
  },
  watch: {
    "formData.password"(value) {
      if (this.formData.password_confirmation) {
        if (value != this.formData.password_confirmation) {
          this.password_error =
            "Password and password confirmation do not match.";
        } else {
          this.password_error = "";
        }
      } else {
        this.password_error = "";
      }
    },
    "formData.password_confirmation"(value) {
      if (value != this.formData.password) {
        this.password_error =
          "Password and password confirmation do not match.";
      } else {
        this.password_error = "";
      }
    },
    modalId(value) {
      if (value == "createUser") {
        this.clearData();
        this.getElements();
      }
    },
  },
};
</script>