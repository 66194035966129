<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='changePassword'"
      tabindex="-1"
      role="dialog"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Change User Password</h5>
            <a class="close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="changePassword"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label"><b>{{formData.name}}</b></label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Password</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.password.$error }">
                            <input
                              type="password"
                              class="form-control border-radius-0"
                              placeholder="Password"
                              @blur="$v.formData.password.$touch()"
                              v-model.trim="formData.password"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.password.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.password.required"
                            >Password is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.password.minLength"
                            >At least 6 character password.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.password.minLength"
                            >Max 50 character password.</p>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Password Confirmation</label>
                          </div>
                          <div
                            class="col-md-6"
                            :class="{ invalid: $v.formData.password_confirmation.$error }"
                          >
                            <input
                              type="password"
                              class="form-control border-radius-0"
                              placeholder="Password Confirmation"
                              @blur="$v.formData.password_confirmation.$touch()"
                              v-model.trim="formData.password_confirmation"
                              required
                            />
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.password_confirmation.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.password_confirmation.required"
                            >Password confirmation is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.password_confirmation.minLength"
                            >At least 6 character password confirmation.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.password_confirmation.minLength"
                            >Max 50 character password confirmation.</p>
                          </div>
                          <div
                            class="col-md-12 text-right error_level"
                            v-if="password_error"
                          >{{password_error}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">{{(disableSubmitButton)?'Submitting...':'Submit'}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  maxLength,
  minLength,
  numeric,
  required,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import Services from "./Services/Services";

export default {
  computed: {
    ...mapGetters([
      "apiRoot",
      "token",
      "dataId",
      "processing",
      "fileLocation",
      "eventMessage",
      "dataLists1",
      "modalId",
      "modalState",
    ]),
    ...mapGetters("users",["userLists"])
  },
  data() {
    return {
      password_error: null,
      formData: {
        id:"",
        name:"",
        password: "",
        password_confirmation: "",
      },
      disableSubmitButton:false,
    };
  },
  validations: {
    formData: {
      password: { required, minLength: minLength(6), maxLength: maxLength(50) },
      password_confirmation: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
    },
  },
  methods: {
    selectUser(){
        let id = this.dataId;
        let details = this.userLists.find(function(data){
            return data.id == id;
        });
        this.formData.id = id;
        this.formData.name = details.name; 
    },
    changePassword() {
      if (!this.$v.formData.$invalid) {
        this.disableSubmitButton = true;
        Services.changePasswordByAdmin(this.formData).then(res=>{
            this.setNotification(res.data.success_message);
            this.$emit('parent-event');
            this.$store.dispatch("modalClose");
        }).catch(err=>{
          if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
               let error = err.response.data.error_message;
                this.setNotification(error);
             }
        }).finally(()=>{
          this.disableSubmitButton =false;
        })
      } else {
        this.$store.commit("setEventMessage", `Please fill required fields.`);
      }
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    clearData(){
      this.password_error= null;
      this.formData.password="";
      this.formData.password_confirmation="";
    }
  },
  watch: {
    "formData.password"(value) {
      if (this.formData.password_confirmation) {
        if (value != this.formData.password_confirmation) {
          this.password_error =
            "Password and password confirmation do not match.";
        } else {
          this.password_error = "";
        }
      } else {
        this.password_error = "";
      }
    },
    "formData.password_confirmation"(value) {
      if (value != this.formData.password) {
        this.password_error =
          "Password and password confirmation do not match.";
      } else {
        this.password_error = "";
      }
    },
    modalId(value){
      if(value == 'changePassword'){
        this.clearData();
        this.selectUser();
      }
    },
  },
};
</script>